<template>
    <div class="nk-app-root">
        <!-- main @s -->
        <div class="nk-main ">
            <!-- wrap @s -->
            <div class="nk-wrap nk-wrap-nosidebar" style="padding-top:0px !important;">
                <!-- content @s -->
                <div class="nk-content ">
                    <div class="nk-block nk-block-middle nk-auth-body  wide-xs">
                        <div class="brand-logo pb-4 text-center">
                            <a href="https://softsuite.co.uk" class="logo-link">
                               <img class="logo-light logo-img" src="@/assets/images/logos/logo-darkbg1.png"  alt="logo">
                               <img class="logo-dark logo-img" src="@/assets/images/logos/logo-lightbg1.png" alt="logo-dark">
                            </a>
                        </div>
                        <div class="card card-bordered">
                            <div class="card-inner card-inner-lg">
                                <div class="nk-block-head">
                                    <div class="nk-block-head-content">
                                        <h5 class="nk-block-title">Welcome to SoftSuite</h5>
                                        <div class="nk-block-des">
                                            <p>Please enter a password below to begin using SoftSuite.</p>
                                        </div>
                                    </div>
                                </div> 
                                    <div class="form-group">
                                        <div class="form-label-group">
                                            <label class="form-label" for="default-01">Password</label>
                                        </div>
                                        <div class="form-control-wrap">
                                            <small class="text-danger" v-if="passwordError.hasError">{{passwordError.text}}</small>
                                            <input  v-model="password" type="password" class="form-control form-control-lg" id="default-01" placeholder="Enter a password">
                                            
                                      </div>
                                    </div>
                                    <div class="form-group">
                                        <button :disabled="passwordError.hasError"  v-on:click="completeConfirmation()" class="btn btn-lg btn-primary btn-block">Set Password</button> 
                                    </div> 
                                <div class="form-note-s2 text-center pt-4">
                                    <router-link :to="{name: 'Login'}"><strong>Return to login</strong></router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="nk-footer nk-auth-footer-full">
                        <div class="container wide-lg">
                            <div class="row g-3">
                                <div class="col-lg-6 order-lg-last">
                                    <ul class="nav nav-sm justify-content-center justify-content-lg-end">
                                        <li class="nav-item">
                                            <a class="nav-link" href="https://softsuite.co.uk/termsandconditions">Terms & Condition</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" href="ttps://softsuite.co.uk/privacypolicy">Privacy Policy</a>
                                        </li> 
                                    </ul>
                                </div>
                                <div class="col-lg-6">
                                    <div class="nk-block-content text-center text-lg-left">
                                        <p class="text-soft">&copy; 2021+ <a href="https://ravenware.co.uk">Ravenware Ltd</a> All Rights Reserved.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- wrap @e -->
            </div>
            <!-- content @e -->
        </div>
        <!-- main @e -->
    </div>
</template>
<script> 
export default {
  watch: {
    'password': function () {
      this.checkPasswordComplexity()
    },
  },
  name: 'AirRegister',
  data: function () {
    return {
      failed: false,
      userId: null,
      code: null,
      passwordStrengthbar: 0,
      passwordError: { hasError: true, text: '' },
      password: null
    }
  },
  created () {
    // get card or use existing.
     if (this.$route.query.id != null && this.$route.query.cid != null) {
       this.userId = this.$route.query.id
       this.code = this.$route.query.cid
     } else {
       this.failed = true
     }
  },
  methods: {
    checkPasswordComplexity () {
      if (this.password) {
        var strength = 0
        this.passwordError = { hasError: false, text: '' }
        if (this.password.match(/[a-z]+/)) {
          strength += 1
        } else {
          this.passwordError.hasError = true
          this.passwordError.text = 'Password must contain a lowercase letter'
          this.passwordStrengthbar.value = 0
          return false
        }
        if (this.password.match(/[A-Z]+/)) {
          strength += 1
        } else {
          this.passwordError.hasError = true
          this.passwordError.text = 'Password must contain 1 uppercase letter'
          this.passwordStrengthbar.value = 0
          return false
        }
        if (this.password.match(/[0-9]+/)) {
          strength += 1
        } else {
          this.passwordError.hasError = true
          this.passwordError.text = 'Password must contain a number'
          this.passwordStrengthbar.value = 0
          return false
        }
        if (this.password.match(/[$@#&!]+/)) {
          strength += 1
        } else {
          this.passwordError.hasError = true
          this.passwordError.text = 'Password must contain a symbol e.g $@#&!'
          this.passwordStrengthbar.value = 0
          return false
        }
        if (this.password.length < 6) {
          this.passwordError.hasError = true
          this.passwordError.text = 'Minimum number of characters is 6'
          this.passwordStrengthbar.value = 0
          return false
        }
        switch (strength) {
          case 0:
            this.passwordStrengthbar.value = 0
            break

          case 1:
            this.passwordStrengthbar.value = 25
            break

          case 2:
            this.passwordStrengthbar.value = 50
            break

          case 3:
            this.passwordStrengthbar.value = 75
            break

          case 4:
            this.passwordStrengthbar.value = 100
            break
        }
      } else {
        return false
      }
    },
    completeConfirmation () {
      var creds = { userId: this.$route.query.id, confirmationLinkId: this.$route.query.cid, password: this.password }
      this.$http.post('/users/complete_Onboarding/', creds)
        .then((response) => {
          if (response.data.result === true) {
            this.login(response.data.userName, this.password)
          } else {
            this.$message.error(response.data.message || 'Unable to sign in. Please contact support')
          }
        })
        .catch(() => {
          this.$message.error('There has been an error')
        })
    },
    login (userName,password) {
        this.connecting = true
          let credentials = {userName: userName, password: password}
          this.$http.post('/auth/login', credentials)
             .then((response) => {
               if (response.data != null && response.data.settings != null && response.data.token != null) {
                 this.$store.commit('updateUserSettings', response.data.settings)
                 localStorage.trimToken = response.data.token
                 
                this.$notification['success']({
                   message: 'Logged In',
                   description: 'Welcome back to SoftSuite',
                 })
                 let settings  = response.data.settings
                this.$nextTick(() => {
                    this.$router.push({ name: settings.dashboard })
                });
                 this.connecting = false
               } else {
                this.connecting = false
                 this.$notification['warning']({
                   message: response.statusText,
                   description: response.message,
                 })
               }
             })
             .catch(error => {
                this.connecting = false
               this.$notification['warning']({
                 message: 'There was an error connecting to the API' || 'Error',
                 description: error.message || 'Unable to connect to the database please contact support',
               })
             })
    }, 
  },
}
</script>
<style>
 
</style>
